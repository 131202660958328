import {
  Branch,
  Chapter,
  ChapterBranch,
  UserBoughtChapter,
  UserChapterLike,
} from '@prisma/client'
import { getCatalogUrl } from './global.helper'

export const processBranchChapters = (params: {
  chapters: (Chapter & {
    chapterBranches: (ChapterBranch & {
      userChapterLikes: UserChapterLike[]
      userBoughtChapters: UserBoughtChapter[]
    })[]
  })[]
}) => {
  return params.chapters.map(({ chapterBranches, ...rest }) => ({
    ...rest,
    chapterBranches: chapterBranches.map(
      ({ userChapterLikes, userBoughtChapters, ...chapterBranchRest }) => ({
        ...chapterBranchRest,
        userChapterLike: userChapterLikes?.[0] || null,
        userBoughtChapter: userBoughtChapters?.[0] || null,
      }),
    ),
  }))
}

export const getActiveBranch = (
  branchParam: number | null,
  branches: Branch[],
) => branches.find((branch) => branch.id === branchParam) || null

export const getFirstChapter = (params: {
  chapter: (Chapter & { chapterBranches: ChapterBranch[] }) | null
  activeBranchId: number | null
}) => {
  const { chapter, activeBranchId } = params

  if (!chapter || !chapter.chapterBranches.length) {
    return null
  }

  const { chapterBranches, ...restOfChapter } = chapter

  const chapterBranch =
    chapterBranches.find(
      (chapterBranch) => chapterBranch.branchId === activeBranchId,
    ) || chapterBranches[0]

  return { ...restOfChapter, chapterBranch }
}

export const getBookUrl = (
  bookSlug: string,
  branchId?: number | null | string,
) => {
  return `${getCatalogUrl()}/${bookSlug}${
    branchId ? `?branchId=${branchId}` : ''
  }`
}
