'use client'

import { UserIcon } from '@heroicons/react/16/solid'
import { Book, Bookmark } from '@prisma/client'
import clsx from 'clsx'
import Link from 'next/link'
import { memo } from 'react'
import {
  getBookImage,
  getBookTitle,
  getBookUrl,
  getBookmarkClass,
  isBlur,
} from 'src/app/lib/utils/book.util'
import { isSafe } from 'src/app/lib/utils/main.util'
import { Icon } from 'src/components/common/Icon'
import { BookTypeEnum } from 'src/enums'
import { t } from 'src/helpers/translate.helper'
import { BookImage } from '../../BookImage'
import { BookStatisticType } from '../SliderCard'

export const CatalogCard = memo(function CatalogCard(props: {
  book: Book & {
    bookmark?: Bookmark
    bookStatistic?: BookStatisticType
  }
  imageClassName?: string
  isUserAuth: boolean
}) {
  const _blank = isSafe()
    ? props.book.isSafe
      ? undefined
      : '_blank'
    : undefined

  const getColor = () => {
    if (!props.book.bookStatistic || props.book.bookStatistic.reviews < 10) {
      return ''
    }

    const rate = props.book.bookStatistic.rate

    if (rate >= 4.5) {
      return 'text-success'
    }

    if (rate < 4) {
      return 'text-danger'
    }

    return ''
  }

  return (
    <Link
      key={props.book.id}
      className="grid relative group auto-rows-max"
      target={_blank}
      href={getBookUrl(props.book.slug)}
    >
      {props.book?.bookmark && (
        <div
          className={clsx(
            'absolute -top-1 left-3 z-10',
            getBookmarkClass(props.book.bookmark.type),
          )}
        >
          <Icon name="bookmarkFull" className="!w-5 !h-5 md:w-6 md:h-6" />
        </div>
      )}

      <BookImage
        isBlur={isBlur(props.isUserAuth, props.book)}
        src={getBookImage(props.book, 'imageHigh')}
        alt={getBookTitle(props.book)}
        fill
        isHoverEffect
        commonClassName="rounded [&_>_div]:rounded dark:bg-black-800 bg-gray-200 h-[calc((100vw_-_32px)_/_3_*_1.5)] md:h-[calc((100vw_-_336px)_/_5_*_1.5)] xl:h-[calc(944px_/_5_*_1.5)]"
        className={clsx(props.imageClassName)}
      />

      <div
        className={clsx(
          'grid gap-x-1.5 text-xs mt-1 grid-flow-col justify-start items-center',
          getColor(),
        )}
      >
        <span className="font-medium max-md:hidden line-clamp-1 text-gray-primary mr-2">
          {props.book.type
            ? t(BookTypeEnum.getLabels()[props.book.type])
            : undefined}
        </span>

        <Icon name="star" className="w-4" />
        <span>{props.book.bookStatistic?.rate || 0.0}</span>
        <span className="text-gray-primary">|</span>
        <UserIcon className="w-4 text-gray-primary" />
        <span className="text-gray-primary mr-1">
          {props.book.bookStatistic?.reviews || 0}
        </span>
      </div>

      <h4 className="mt-2 font-medium group-hover:text-primary line-clamp-2 text-tiny leading-4 md:leading-5 md:text-base">
        {props.book.title || props.book.titleEn || ''}
      </h4>
    </Link>
  )
})
